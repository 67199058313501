<template>
  <div>
    <TitleWithBackButton title="" back-route="Settings" icon="mdi-graphql"></TitleWithBackButton>
    <CompanyCommercialData class="pa-5"></CompanyCommercialData>
  </div>
</template>

<script>
import CompanyCommercialData from "./CompanyCommercialData.vue";
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';

export default {
  name: "StoreData",
  components: {
    CompanyCommercialData,
    TitleWithBackButton
  },
  data: function () {
    return {};
  },
};
</script>

<style></style>